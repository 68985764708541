import React from 'react';
import classNames from 'classnames';
import XSSTranslate from 'components/XSSTranslate';
import './hero.scss';

const Component = React.forwardRef(({ langkey, id, bgdark, children, xssWhiteList }, ref) => {
    const heroClass = classNames({
        'hero': true,
        'blue-dark': bgdark,
    });
    return (
        <section className={ heroClass } id={ id } ref={ ref }>
            {
                langkey ?  <XSSTranslate value={ langkey } tag="h1" whiteList={ xssWhiteList } /> : null
            }
            {
                children ? (
                    <div className="description">
                        {
                            React.Children.map(children, item => <>{ item }</>)
                        }
                    </div>
                ) : null
            }
        </section>
    );
});

export default Component;
