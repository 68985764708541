const lang = {
    'caption-1': '自由騎行',
    'description-1-1': '出發，享受騎乘，旅程長短由你決定！GoShare App 整合 Gogoro Network® 智慧電池交換平台，讓你快速查詢附近的 GoStation 電池交換站輕鬆換電，再也不受里程焦慮束縛！',
    'description-1-2': '* GoStation 實際站點請以 GoShare App 或 Gogoro Network 官網為主。',
    'read-more': '了解更多',
    'caption-2': '景點暢遊',
    'description-2-1': '專為熱門景點量身打造，GoShare DOTS 24 小時定點借還服務，於營運範圍內的固定站點內定點借還，讓你在人氣景點暢行無阻！快找到 GoShare App 地圖內亮黃色圓點的 GoShare DOTS 車輛，開始體驗吧！',
    'caption-3': '多元費率',
    'description-3-1': '提供騎乘費率、暫停費率等靈活費用計算方式。臨停、等人，一鍵切換超划算！',
    'description-3-2': '想要長時間租借？「日租方案」提供更自在的選擇，自由切換車款，享受更輕鬆的行程安排！',
    'description-3-3': '* 實際費率與優惠活動請以 GoShare App 為主。',
};

export default lang;
