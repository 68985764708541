const lang = {
    'company-name': 'Company Name',
    'company-website': 'Company Website',
    'located-country': 'Located Country',
    'located-city': 'Located City',
    'first-name': 'First Name',
    'last-name': 'Last Name',
    'phone': 'Phone',
    'email': 'Email',
    'phone-placeholder': 'Please begin with your country code.',
    'valueMissing': 'Please complete this required field.',
    'patternMismatch': 'Email must be formatted correctly.',
    'general-error-message': 'Please check this content of field',
    'submit': 'Submit'
}

export default lang;