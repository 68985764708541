const lang = {
    'lang': '繁體中文',
    'notify-me': '搶先體驗',
    'email-placeholder-unfocus': '掌握最新資訊',
    'email-placeholder-focus': '輸入電子郵件',
    'submit': '送出',
    'contact-us': '商務合作',
    'all-rights-reserved': '© %{year} Gogoro 及/或其關係企業。版權所有。Gogoro、Gogoro Network、GoShare、Smartscooter、GoStation、其等之標誌、以及本網站中其他Gogoro產品及服務名稱及標誌，皆為 Gogoro Inc. 之商標或註冊商標。本網站中提及之其他公司名稱、產品名稱、服務名稱及標誌，分別為其所有權人之商標。',
    'privacy-policy': '隱私權政策',
    'terms-and-conditions': '使用條款',
    'terms-and-service': '服務條款',
    'taoyuan': '桃園',
    'invalid-email': '請輸入有效的Email',
    'go-now': '通知我',
    'download-app': '立即下載',
    'available-platform': '同步支援 iOS 與 Android 系統',
    'nav-about-us': '關於我們',
    'goshare-jp': 'GoShare 石垣島租借服務',
    'our-service': '服務內容',
    'nav-partnerships': '合作夥伴',
    'newsroom': '新聞室',
    'career': '加入我們',
    'lost_found': '失物招領',
    'general-error-message': '發生錯誤，請再試一次:(',
    'goshare-data-notice': 'GoShare個資告知書',
};

export default lang;
