const lang = {
    'hero-caption': 'GoShare，<br />不一樣的移動<br />共享服務。',
    'vision': '全球許多城市都在尋找有效的交通解決方案，來解決都市交通壅塞問題。在 Gogoro，我們以打造更乾淨、無污染的智慧城市為使命，提供更有效的都市移動解決方案， GoShare — 全球首創「端至端」移動共享方案，因此誕生。',
    'head-statement': '「我們以 GoShare App 串起 Gogoro 及 Powered by Gogoro Network (PBGN) 智慧電動機車、Gogoro Network 等生態系資源，讓消費者一鍵在手，就能夠輕鬆享受 24 小時簡單便捷的騎乘體驗，並協助城市與政府一同打造永續的都市生活環境。」',
    'head-of-goshare': 'GoShare 新事業總監，姜家煒',
    'unique-experience-caption': '獨一無二的騎乘體驗',
    'unique-experience-content': '<span class="spec-text-nowrap">我們使用 Gogoro 與 PBGN 智慧電動機車，提供用戶</span>絕佳的騎乘體驗，享受高品質、隨時連網、創新且功能完備的服務。',
    'fast-swapping-caption': '快速方便的 Gogoro Network 智慧電池交換平台',
    'fast-swapping-content': '我們讓所有使用者體驗到自由的騎乘體驗，有了 Gogoro Network 智慧電池交換平台，使用者可在旅程中快速更換電池，不受里程焦慮束縛，想騎多遠自己決定。',
    'one-click-caption': '業界領先的移動共享軟體解決方案 ',
    'one-click-content': 'GoShare 整合業界頂尖的 AI 人工智慧、機器學習與雲端營運管理系統，讓用戶透過簡單好用的 GoShare App，快速完成身份認證，一鍵快速搜尋身邊最適合的 GoShare 車輛，體驗騎乘新樂趣。',
};

export default lang;
