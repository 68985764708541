import React from 'react';
import { Translate } from 'react-redux-i18n';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import LocaleNavLink from 'components/LocaleNavLink';
import {
    ROOT,
    ABOUT_US,
    SERVICE,
    PARTNERSHIPS,
} from 'constants/route';
import StandardContainer from 'components/StandardContainer';
import ButtonDownloadApp from 'components/ButtonDownloadApp';
import whiteLogo from 'assets/img/go-share-white.svg';
import blackLogo from 'assets/img/go-share-dark.svg';
import './header.scss';

const Header = ({ className, show, sticky, darkLogo, darkLink, bgdark, innerRef }) => {
    const headerClass = classNames({
        'header': true,
        show: !!show,
        dark: darkLink,
        'blue-dark': bgdark,
        sticky,
        [className]: !!className,
    });

    return (
        <StandardContainer tag="header" className={ headerClass } innerRef={ innerRef }>
            <LocaleNavLink className="logo-link" to={ ROOT }>
                <img src={ darkLogo ? blackLogo : whiteLogo } alt="goshare logo" />
            </LocaleNavLink>
            <nav className="nav">
                <LocaleNavLink className="link" to={ ABOUT_US }>
                    <Translate value="nav-about-us" />
                </LocaleNavLink>
                <LocaleNavLink className="link" to={ SERVICE }>
                    <Translate value="our-service" />
                </LocaleNavLink>
                <LocaleNavLink className="link" to={ PARTNERSHIPS }>
                    <Translate value="nav-partnerships" />
                </LocaleNavLink>
                <ButtonDownloadApp />
            </nav>
        </StandardContainer>
    );
};

Header.propType = {
    className: PropTypes.string,
    show: PropTypes.bool,
    sticky: PropTypes.bool,
    darkLogo: PropTypes.bool,
    darkLink: PropTypes.bool,
    innerRef: PropTypes.shape(),
};

Header.defaultProps = {
    className: '',
    show: false,
    sticky: false,
    darkLogo: false,
    darkLink: true,
    innerRef: undefined,
};

export default Header;
