import { fetchYear } from 'api';
import { GET_YEAR } from 'constants/action-type';

export const getYear = () => async (dispatch) => {
    const response = await fetchYear();
    dispatch({
        type: GET_YEAR,
        payload: response.data
    })
}

export const toggleSplash = isRunning => dispatch => {
    return Promise.resolve(dispatch({
        type: 'RUNNING_SPLASH',
        data: isRunning,
    }));
};
