import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import XSSTranslate from 'components/XSSTranslate';
import PropTypes from 'prop-types';
import Footer from 'components/Footer';
import StandardContainer from 'components/StandardContainer';
import ViewContainer from 'components/ViewContainer';
import Promotion from 'components/Promotion';
import Header from 'components/Header';
import heroBg from 'assets/img/p2/img-about-hero-bg.svg';
import scooterDesktopImg from 'assets/img/p3/img-about-usp1@2x.jpg';
import scooterMobileImg from 'assets/img/p3/img-about-usp1-mobile@2x.jpg';
import networkDesktopImg from 'assets/img/p2/img-about-usp2.jpg';
import networkMobileImg from 'assets/img/p2/img-about-usp2-mobile.jpg';
import mobilityDesktopImg from 'assets/img/p3/img-about-usp3@2x.jpg';
import mobilityMobileImg from 'assets/img/p3/img-about-usp3-mobile@2x.jpg';
import './about-us.scss';


class AboutUs extends Component {
    static propTypes = {
        i18n: PropTypes.shape().isRequired,
    };

    render() {
        const stringXSSWhiteList = {
            span: ['class'],
        };
        return (
            <ViewContainer className="about-us">
                <Header show />
                <StandardContainer className="hero-about-us">
                    <img className="hero-image" src={ heroBg } alt="" />
                    <div className="hero-statement-wrap">
                        <XSSTranslate className="hero-statement" value="about-us.hero-caption" tag="h1" />
                    </div>
                </StandardContainer>
                <StandardContainer className="section vision">
                    <Translate className="statement" value="about-us.vision" tag="p" />
                </StandardContainer>
                <StandardContainer className="section achievement">
                    <Translate className="statement" value="about-us.head-statement" tag="p" />
                    <Translate className="head-of-goshare" value="about-us.head-of-goshare" tag="em" />
                </StandardContainer>
                <section className="features">
                    <div className="feature-wrapper">
                        <article className="col description">
                            <Translate className="caption" value="about-us.unique-experience-caption" tag="h3" />
                            <XSSTranslate className="content" value="about-us.unique-experience-content" tag="p" whiteList={ stringXSSWhiteList } />
                        </article>
                        <div className="col graphic scooter">
                            <picture>
                                <source srcSet={ scooterMobileImg } media="(max-width: 920px)" />
                                <img src={ scooterDesktopImg } alt="" />
                            </picture>
                        </div>
                    </div>

                    <div className="feature-wrapper reverse">
                        <article className="col description">
                            <Translate className="caption" value="about-us.fast-swapping-caption" tag="h3" />
                            <Translate className="content" value="about-us.fast-swapping-content" tag="p" />
                        </article>
                        <div className="col graphic energy-network">
                            <picture>
                                <source srcSet={ networkMobileImg } media="(max-width: 920px)" />
                                <img src={ networkDesktopImg } alt="" />
                            </picture>
                        </div>
                    </div>

                    <div className="feature-wrapper">
                        <article className="col description">
                            <Translate className="caption" value="about-us.one-click-caption" tag="h3" />
                            <Translate className="content" value="about-us.one-click-content" tag="p" />
                        </article>
                        <div className="col graphic mobility">
                            <picture>
                                <source srcSet={ mobilityMobileImg } media="(max-width: 920px)" />
                                <img src={ mobilityDesktopImg } alt="" />
                            </picture>
                        </div>
                    </div>
                </section>
                <Promotion ghostHeader={ false } />
                <Footer />
            </ViewContainer>
        );
    }
}

export default connect(state => ({
    i18n: state.i18n,
}))(AboutUs);
