import React, { Component } from 'react';
import { Translate } from 'react-redux-i18n';
import XSSTranslate from 'components/XSSTranslate';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { renderAnimation } from 'helpers/lottie';
import logoAnimationConfig from 'assets/lottie/goshare_logo_animation.json';
import StandardContainer from 'components/StandardContainer';
import ButtonDownloadApp from 'components/ButtonDownloadApp';
import './hero.scss';

class Hero extends Component {
    static propType = {
        innerRef: PropTypes.shape(),
    };

    static defaultProps = {
        innerRef: React.createRef(),
    };

    constructor(props) {
        super(props);

        this.elSvg = React.createRef();
        this.state = {
            animationDone: false,
        };
        this.timer = undefined;

    }

    componentDidMount() {
        this.heroAnimation = renderAnimation(this.elSvg.current, logoAnimationConfig, false);
        // speeding transition
        this.timer = setTimeout(this.handleAnimationDone, 3000);

        this.heroAnimation.addEventListener('complete', this.handleAnimationDone);
    }

    componentWillUnmount() {

        clearTimeout(this.timer);
        this.heroAnimation.removeEventListener('complete', this.handleAnimationDone);

        this.heroAnimation.destroy();
    }

    handleAnimationDone = () => {
        this.setState({
            animationDone: true,
        });
    }

    render() {
        const { animationDone } = this.state;
        const { innerRef } = this.props;
        const animationClass = classNames({
            'cover-image': true,
            'transition-section': true,
            'shrink': animationDone,
        });
        const captionClass = classNames(['caption', 'transition-section', 'slide-up']);
        const articleClass = classNames(['hero-description', 'transition-section', 'slide-up']);
        const downloadClass = classNames(['download', 'transition-section', 'slide-up']);
        const wrapperClass = classNames({
            'description-wrapper': true,
            'show': animationDone,
        });
        const descriptionXSSWhiteList = {
            span: ['class']
        };

        return (
            <StandardContainer innerRef={ innerRef } className="home-hero">
                <div ref={ this.elSvg } className={ animationClass } />
                <div className={ wrapperClass }>
                    <Translate className={ captionClass } value="hero.hop-on" tag="h1" />
                    <article className={ articleClass }>
                        <XSSTranslate className="decription" value="hero.decription-1" tag="p" whiteList={ descriptionXSSWhiteList } />
                        <XSSTranslate className="decription" value="hero.decription-2" tag="p" whiteList={ descriptionXSSWhiteList } />
                    </article>
                    <div className={ downloadClass }>
                        <ButtonDownloadApp />
                        <Translate className="available-platform" value="available-platform" tag="p" />
                    </div>
                </div>
            </StandardContainer>
        );
    }
}

export default Hero;
