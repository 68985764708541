const lang = {
    'hero-caption': 'Discover the GoShare Difference.',
    'vision': 'We know that many of the world’s cities are looking for smart solutions to their transportation and congestion problems. We at Gogoro want to support cleaner, smarter cities, and that is why we created GoShare. The world’s first end-to-end mobility sharing platform.',
    'head-statement': '"Through the GoShare App, we integrate Gogoro Smartscooters, Powered by Gogoro Network (PBGN) scooters, and Gogoro Network to let users enjoy one click access to an easy and fun riding experience 24/7. GoShare also provides cities and governments with the most intelligent and sustainable mobility solution to meet their aggressive smart city goals and initiatives."',
    'head-of-goshare': 'Henry Chiang, Head of GoShare',
    'unique-experience-caption': 'A ride like no other',
    'unique-experience-content': 'With Gogoro Smartscooters and PBGN scooters, GoShare delivers the same premium experience that Gogoro is known for, with high performance, wireless connectivity, and innovative smart features tailored to daily mobility.',
    'fast-swapping-caption': 'Fast, convenient battery swapping',
    'fast-swapping-content': 'We truly let riders go anywhere. Taking advantage of the Gogoro Network, GoShare riders are able to swap batteries and extend their energy and riding distance for as long as they need.',
    'one-click-caption': 'Advanced mobility sharing software solutions',
    'one-click-content': 'Powered by the leading AI engine, machine learning, and real-time fleet management, GoShare enables users to complete the authentication process easily, locate, and reserve a GoShare scooter in just a few minutes for an exciting ride.',
};

export default lang;
