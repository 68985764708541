import React from 'react';
import LocaleNavLink from 'components/LocaleNavLink';


const OutsideLink = ({ value, to, className }) => {
    return (
        <a
            href={ to }
            target="_blank"
            rel="noopener noreferrer"
            className={ className }
        >{ value }</a>
    );
};

const renderLink = ({ type, value, style, href }, target) => {
    return (type === 'outside') ? (
        <OutsideLink value={ value } to={ href } className={ style } />
    ) : (
        <LocaleNavLink to={ href } className={ style } target={ target } >{ value }</LocaleNavLink>
    );
};
const replacLink = (string, replacements, target) => {
    let result = [];
    Object.keys(replacements).forEach((replacement) => {
        const link = renderLink(replacements[replacement], target);
        const list = string.split(`%{${replacement}}`);
        list.forEach(item => {
            result.push(item);
            result.push(link);
        });
        result.pop();
    });
    return result;
};

const StringWithLink = ({
    tag = 'p',
    value,
    link,
    target
}) => {

    const Tag = tag;
    const content = replacLink(value, link, target);
    const renderContent = (list) => {
        return (list.map((item, i) => {
            return ( typeof item === 'string' ) ?
            (<span key={ i }>{ item }</span>) : (
                <React.Fragment key={ i }>{ item }</React.Fragment>
            );
        }));
    };
    return (
        <Tag>
            { renderContent(content) }
        </Tag>
    );
};

export default StringWithLink;
