import cnc from 'assets/img/p2/p-logo-cnc.png';
import globe from 'assets/img/p2/p-logo-globe.png';
import ruentex from 'assets/img/p2/p-logo-ruentex.png';
import applepay from 'assets/img/p2/p-logo-applepay.svg';
import breeze from 'assets/img/p2/p-logo-breeze.svg';
import dominos from 'assets/img/p2/p-logo-dominos.svg';
import fet from 'assets/img/p2/p-logo-fet.svg';
import ms from 'assets/img/p2/p-logo-ms.svg';
import nanshan from 'assets/img/p2/p-logo-nanshan.svg';
import sinopac from 'assets/img/p2/p-logo-sinopac.svg';
import taishin from 'assets/img/p2/p-logo-taishin.svg';
import tft from 'assets/img/p2/p-logo-tft.svg';
import visa from 'assets/img/p2/p-logo-visa.svg';
import rakuten from 'assets/img/p2/p-logo-rakuten.svg';
import gojek from 'assets/img/p2/p-logo-gojek.svg';
import tsmc from 'assets/img/p2/p-logo-tsmc.svg';

export const partnerships = [
    {
        id: 'cnc',
        logo: cnc,
    },
    {
        id: 'globe',
        logo: globe,
    },
    {
        id: 'gojek',
        logo: gojek,
    },
    {
        id: 'tsmc',
        logo: tsmc,
    },
    {
        id: 'applepay',
        logo: applepay,
    },
    {
        id: 'breeze',
        logo: breeze,
    },
    {
        id: 'dominos',
        logo: dominos,
    },
    {
        id: 'fet',
        logo: fet,
    },
    {
        id: 'ms',
        logo: ms,
    },
    {
        id: 'nanshan',
        logo: nanshan,
    },
    {
        id: 'rakuten',
        logo: rakuten,
    },
    {
        id: 'ruentex',
        logo: ruentex,
    },
    {
        id: 'sinopac',
        logo: sinopac,
    },
    {
        id: 'taishin',
        logo: taishin,
    },
    {
        id: 'tft',
        logo: tft,
    },
    {
        id: 'visa',
        logo: visa,
    },

];
