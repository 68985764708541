import React, { Component } from 'react';
import whiteLogo from 'assets/img/go-share-white.svg';
import gogoroLogo from 'assets/img/gogoro-logo-small.svg';

import './ma-program.scss';

class MAProgram extends Component {

    constructor(props) {
        super(props);
        this.state = {
            registrationLink: 'https://forms.gle/KdhGi8mSf1nvmXyR9',
        };
    }

    renderMail() {
        return <a className="email" href="mailto:ma.program@gogoro.com">ma.program@gogoro.com</a>;
    }


    render() {
        const { registrationLink } = this.state;
        return (
            <div className="ma-program">
                <div className="ma-header">
                    <img className="logo" src={ whiteLogo } alt="goshare logo" />
                    <h1 className="title">2020 MA PROGRAM</h1>
                    <p className="sub-title">While others wait for the world to change, we strive to change the world. Join us!</p>
                </div>
                <div className="wrapper">
                    <section className="section">
                        <h2 className="title-h2">GoShare 2020 Management Associate Program</h2>
                        <p className="para">GoShare Management Associate Program (MA Program) was established to discover the best and brightest young minds from around the world. This talent development program looks to cultivate and grow the next generation of business leaders. Hop on and send in your application!</p>
                        <p className="para">We blend innovation, technology and sustainable energy into a compact solution that is reshaping our urban culture. Are you ready to ignite the next stages of a global movement and join a business model with endless opportunities? Then Let’s GO!</p>
                    </section>
                    <section className="section no-mb">
                        <h3 className="title-h3">
                            <span>This MA will experience a lot,</span>
                            <span> here’s a snapshot!</span>
                        </h3>
                        <ul className="list">
                            <li>Over two years you will constantly be rotating through different teams, honing your skill set in all areas and unearthing your true passion</li>
                            <li>You will be coached and guided by business leaders and domain experts, becoming a GoShare Jedi master</li>
                            <li>Face down real world business challenges across different  territories and come up with real world solutions</li>
                            <li>Participate in large-scale projects and overseas operations</li>
                            <li>Develop new skills that will supercharge your career and get you ready for GoShares strategic roles</li>
                        </ul>
                    </section>
                </div>
                <div className="bg-green">
                    <div className="wrapper">
                        <h2 className="title-h2">Who Are We</h2>
                        <section className="section">
                            <h3 className="title-h3">About Gogoro</h3>
                            <p className="para">Gogoro is leading the world’s technology trends in creating cleaner, smarter and sustainable cities for all. Driven by our purpose to bring positive change through power transformation, we have built an unparalleled ecosystem of Smart-Scooters linked to an  Energy Network which is revolutionising transportation.</p>
                        </section>
                        <section className="section no-mb">
                            <h3 className="title-h3">About GoShare</h3>
                            <p className="para">GoShare® is the world’s first end-to-end mobility sharing platform and solution powered by the integration of Gogoro® smartscooter®, Gogoro Network™, artificial intelligence, advanced cloud management, and GoShare App to deliver an easy, smart and fun experience. Through the GoShare App, GoShare provides 24/7 service that enables customers to find, reserve and ride a Gogoro Smartscooter in a hassle-free and adventurous way. GoShare invites everyone to enjoy the convenience brought by shared mobility, and to create sustainable urban life together.</p>
                        </section>
                    </div>
                </div>
                <div className="wrapper no-pb">
                    <h2 className="title-h2">Who’s Hopping on with Us?</h2>
                    <ul className="list">
                        <li>Someone who holds a bachelor’s degree or above and under 2 years of work experience</li>
                        <li>
                            <span>Someone that is good with numbers, analytical, and able to communicate clearly and effectively</span>
                            <span className="text-block">(High level written and spoken English is a must, second foreign languages are a plus)</span>
                        </li>
                        <li>Someone who creates bold ideas and solves problems. We want the people with the best , critical thinking skills</li>
                    </ul>
                </div>
                <div className="wrapper">
                    <h2 className="title-h2">Timeline</h2>
                    <div className="timeline">
                        <div className="timeline-item">
                            <div className="timeline-dot"></div>
                            <div className="timeline-content">
                                <span className="timeline-date">2020/3/16 (Mon.)</span>
                                <span className="timeline-title">Online Application</span>
                            </div>
                        </div>
                        <div className="timeline-item">
                            <div className="timeline-dot"></div>
                            <div className="timeline-content">
                                <span className="timeline-date">2020/3 (3rd - 4th week)</span>
                                <span className="timeline-title">Online Sharing Seminar
                                    <a className="timeline-link" href={ registrationLink } target='_blank' rel="noopener noreferrer">( Registration Here )</a>
                                </span>
                            </div>
                        </div>
                        <div className="timeline-item">
                            <div className="timeline-dot"></div>
                            <div className="timeline-content">
                                <span className="timeline-date">2020/4/06 (Mon.)</span>
                                <span className="timeline-title">Application Deadline</span>
                            </div>
                        </div>
                        <div className="timeline-item">
                            <div className="timeline-dot"></div>
                            <div className="timeline-content">
                                <span className="timeline-date">2020/4 (3rd - 5th week)</span>
                                <span className="timeline-title">Group Interview & Individual Interview</span>
                            </div>
                        </div>
                        <div className="timeline-item">
                            <div className="timeline-dot"></div>
                            <div className="timeline-content">
                                <span className="timeline-date">2020/ mid-May</span>
                                <span className="timeline-title">Results Announced</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wrapper no-pt">
                    <h2 className="title-h2">
                        <span>Can’t Wait?</span>
                        <span>It just takes a few </span>
                        <span>simple steps</span>
                    </h2>
                    <div className="orders">
                        <div className="orders-item">
                            <div className="orders-number">1</div>
                            <div className="orders-content">
                                <p>Email {this.renderMail()}  the following information. Make sure to introduce yourself and tell us why you are the best fit for the GoShare Management Associate:</p>
                                <ul className="list">
                                    <li>Resume (in pdf format, under 10MB)</li>
                                    <li>A PowerPoint/Keynote deck (in pdf format, under 20 slides)</li>
                                    <li>Email Title: GoShare MA Program Application_Chinese Name_English Name</li>
                                </ul>
                            </div>
                        </div>
                        <div className="orders-item">
                            <div className="orders-number">2</div>
                            <div className="orders-content">
                                <p>Short-listed candidates will be notified via email within 2 weeks after we applications close</p>
                            </div>
                        </div>
                        <div className="orders-item">
                            <div className="orders-number">3</div>
                            <div className="orders-content">
                                <p>Attend the reasoning test and group interview at our Taipei office. Please note that the interview dates will occur in a very narrow window in the second half of April</p>
                            </div>
                        </div>
                        <div className="orders-item">
                            <div className="orders-number">4</div>
                            <div className="orders-content">
                                <p>The final interview will be composed of individual interview and case study interview</p>
                            </div>
                        </div>
                        <div className="orders-item">
                            <div className="orders-number">5</div>
                            <div className="orders-content">
                                <p>Final results will be announced in mid-May</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-blue">
                    <div className="wrapper">
                        <h2 className="title-h2 title-white">A word from our 2019 MA</h2>
                        <div className="quote">
                            <p className="quote-para">“GoShare is the incubator for those who dare to dream.Onboarding GoShare is embarking on a journey full of challenges and uncertainty, yet we fear nothing.”</p>
                            <em className="quote-said">Cheryl Kuo</em>
                        </div>
                        <div className="quote">
                            <p className="quote-para">“GoShare is an environment that propels growth. The endless opportunities to take ownership allowed me to develop a wide spectrum of business skills.”</p>
                            <em className="quote-said">Alan Chou</em>
                        </div>
                    </div>
                </div>
                <div className="bg-teal">
                    <div className="wrapper">
                        <h2 className="title-h2 title-white s-mb">Have Questions?</h2>
                        <p className="para-l">Drop us an Email: { this.renderMail() }</p>
                    </div>
                </div>
                <div className="bg-dark">
                    <div className="ma-footer">
                        <img src={ gogoroLogo } alt="gogoro" className="gogoro-logo"/>
                        <p className="copyright">Copyright © 2020 Gogoro Inc. All rights reserved. Gogoro, Gogoro Network, GoShare, GoStation and Smartscooter are trademarks or registered trademarks of Gogoro Inc. All other marks, names, and logos mentioned herein are the property of their respective owners.</p>
                    </div>
                </div>
            </div>
        );
    }
}
export default MAProgram;
