import React from 'react';
import ViewContainer from 'components/ViewContainer';

import './payment-3ds.scss';

const Done = () => {
    return (
        <ViewContainer>
            <div className="container">
                <div className="congratulations"></div>
                <p className="message">綁定流程已結束，你可以關閉此視窗繼續下一步。</p>
                <p className="eng-message">
                    <small>The binding process is completed, please close the window to continue.</small>
                </p>
            </div>
        </ViewContainer>
    );
};

export default Done;
