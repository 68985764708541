import React from 'react';
import { Translate } from 'react-redux-i18n';
import mobile from 'is-mobile';
import { DOWNLOAD } from 'constants/route';
import LocaleNavLink from 'components/LocaleNavLink';
import './download-app.scss';

const isMobile = mobile();

const Component = () => {
    return (
        isMobile ?
            (
                <a
                    href="https://go.ridegoshare.com/DLApp"
                    target="_blank" rel="noopener noreferrer"
                    className="btn btn-primary btn-download"
                >
                    <Translate value="download-app" />
                </a>
            ) :
            (
                <LocaleNavLink to={ DOWNLOAD } className="btn btn-primary btn-download">
                    <Translate value="download-app" />
                </LocaleNavLink>
            )
    );
};

export default Component;
