import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { detect } from 'detect-browser';
import Footer from 'components/Footer';
import StandardContainer from 'components/StandardContainer';
import ViewContainer from 'components/ViewContainer';
import Promotion from 'components/Promotion';
import { renderAnimation } from 'helpers/lottie';
import Header from 'components/Header';
import { withRouter } from 'react-router-dom';
import MobileDetect from 'mobile-detect';
import Hero from './Hero';
import GoEverywhere from './GoEverywhere';
import smileFaceConfig from 'assets/lottie/web_goshare_smile.json';
// videos
import videoStep1 from 'assets/videos/web_goshare_step1.mp4';
import videoStep2 from 'assets/videos/web_goshare_step2.mp4';
import videoStep3 from 'assets/videos/web_goshare_step3.mp4';
import posterStep1 from 'assets/img/p2/step1-poster.png';
import posterStep2 from 'assets/img/p3/step2-poster.png';
import posterStep3 from 'assets/img/p3/step3-poster.png';
import './home.scss';

const md = new MobileDetect(window.navigator.userAgent);
const isMobile = md.mobile();
const browser = detect();
const showStatic = ['edge', 'ie'].indexOf(browser.name) > -1;
let showVideoIndex = undefined;



class Home extends Component {
    static propTypes = {
        i18n: PropTypes.shape().isRequired,
    };

    constructor(props) {
        super(props);

        this.elHero = React.createRef();
        this.elScooter360 = React.createRef();
        this.elSteps = React.createRef();
        this.elStep1 = React.createRef();
        this.elStep2 = React.createRef();
        this.elStep3 = React.createRef();
        this.elVideoStep1 = React.createRef();
        this.elVideoStep2 = React.createRef();
        this.elVideoStep3 = React.createRef();
        this.elMovingScooter = React.createRef();
        this.elSmileFace = React.createRef();
        this.state = {
            darkHeaderLogo: false,
            darkHeaderLink: true,
            showHeader: false,
            smileFacePos: 0,
            step1: {
                bigNumberTop: 0,
                captionTop: 100,
                contentTop: 100,
            },
            step2: {
                bigNumberTop: 0,
                captionTop: 100,
                contentTop: 100,
            },
            step3: {
                bigNumberTop: 0,
                captionTop: 100,
                contentTop: 100,
            },
            showScooter360: false,
            trimcooter360Wrapper: false,
            movingScooter: false,
        };
    }

    componentDidMount() {
        document.addEventListener('scroll', this.handleScrolling, false);

        if (this.elSmileFace.current) {
            this.smileFaceAnimation = renderAnimation(this.elSmileFace.current, smileFaceConfig, false);
            this.smileFaceAnimation.stop();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.handleScrolling, false);

    }

    handleScrolling = () => {

        const windowScrollTop = window.pageYOffset;
        const windowHeight = window.innerHeight;
        const buffer = windowHeight;
        const heroRect = this.elHero.current.getBoundingClientRect();
        const step2Caption = this.elStep2.current.querySelector('.step-caption');
        const newState = { ...this.state };
        const steps = [{
            parent: this.elStep1.current,
            bigNumber: this.elStep1.current.querySelector('.step-number'),
            caption: this.elStep1.current.querySelector('.step-caption'),
            content: this.elStep1.current.querySelector('.step-content'),
        }, {
            parent: this.elStep2.current,
            bigNumber: this.elStep2.current.querySelector('.step-number'),
            caption: step2Caption,
            content: this.elStep2.current.querySelector('.step-content'),
        }, {
            parent: this.elStep3.current,
            bigNumber: this.elStep3.current.querySelector('.step-number'),
            caption: this.elStep3.current.querySelector('.step-caption'),
            content: this.elStep3.current.querySelector('.step-content'),
        }];

        // header
        newState.showHeader = windowScrollTop >= heroRect.height;

        const scooter360Rect = this.elScooter360.current.getBoundingClientRect();

        if (windowHeight / 1.4 >= scooter360Rect.top) {
            newState.showScooter360 = true;
            newState.trimcooter360Wrapper = true;
        }

        // steps
        steps.forEach(({ parent, bigNumber, caption, content }, i) => {
            const parentRect = parent.getBoundingClientRect();
            const bigNumberRect = bigNumber.getBoundingClientRect();
            const captionRect = caption.getBoundingClientRect();
            const contentRect = content.getBoundingClientRect();

            if (captionRect.top < windowHeight - 150 && captionRect.bottom > 0) {
                const diff = (windowHeight - captionRect.top - 150);

                newState[`step${ i + 1 }`].captionTop = 100 - Math.min(100, diff);
            }

            if (contentRect.top < windowHeight - 150 && contentRect.bottom > 0) {
                const diff = (windowHeight - contentRect.top - 150);

                newState[`step${ i + 1 }`].contentTop = 100 - Math.min(100, diff);
            }

            if (bigNumberRect.bottom < windowHeight) {
                const diff = windowHeight - bigNumberRect.bottom;

                newState[`step${ i + 1 }`].bigNumberTop = Math.min(100, diff);
            }
            else {
                newState[`step${ i + 1 }`].bigNumberTop = 0;
            }

            // videos
            const currentVideo = this[`elVideoStep${ i + 1 }`].current;

            if (parentRect.y < buffer / 2 && parentRect.y + (parentRect.height / 2) > 0) {
                if (typeof showVideoIndex === 'number' && showVideoIndex !== i) {
                    currentVideo.currentTime = 0;
                }

                showVideoIndex = i;

                const promise = currentVideo.play();

                if (promise) {
                    promise.catch(error => {
                        console.error(error);
                    }).then(() => {
                        // Auto-play started
                    });
                }
            }
        });

        // smile face
        const step2Rect = step2Caption.getBoundingClientRect();

        if (this.elSmileFace.current) {
            if (step2Rect.y <= (windowHeight / 2) && step2Rect.y > 0) {
                this.smileFaceAnimation.setDirection(1);
                this.smileFaceAnimation.play();
                const progress = (windowHeight / 2) - step2Rect.y;
                newState.smileFacePos = Math.min(30, progress);
            }
            else if (step2Rect.y <= 0) {
                this.smileFaceAnimation.setDirection(-1);
                this.smileFaceAnimation.play();
                const progress = step2Rect.y;
                newState.smileFacePos = Math.max(0, progress);
            }
            else {
                this.smileFaceAnimation.setDirection(-1);
                this.smileFaceAnimation.play();
                newState.smileFacePos = 0;
            }
        }

        // moving scooter
        const elMovingScooter = this.elMovingScooter.current;
        const movingScooterPos = elMovingScooter.getBoundingClientRect();
        const elScooter360 = this.elScooter360.current;
        const scooter360pos = elScooter360.getBoundingClientRect();

        // `100` is a buffer range
        if (
            windowScrollTop + 100 >= elMovingScooter.offsetTop &&
            windowScrollTop + 100 <= elMovingScooter.offsetTop + movingScooterPos.height
        ) {
            newState.darkHeaderLogo = true;
            newState.darkHeaderLink = true;
        }
        else if (scooter360pos.bottom - (scooter360pos.height * 0.3) >= 0) {
            newState.darkHeaderLogo = false;
            newState.darkHeaderLink = true;
        }
        else {
            newState.darkHeaderLogo = false;
            newState.darkHeaderLink = false;
        }


        if (windowHeight >= movingScooterPos.bottom - 200) {
            newState.movingScooter = true;
        }

        this.setState(newState);
    }

    render() {
        const { showHeader, darkHeaderLogo, darkHeaderLink } = this.state;
        const { step1, step2, step3, movingScooter, smileFacePos } = this.state;
        const { showScooter360, trimcooter360Wrapper } = this.state;
        const stepCaptionStyles = [step1, step2, step3].map(step => {
            return (
                isMobile ?
                    null :
                    {
                        transform: `translateY(${ step.captionTop }px)`,
                        filter: `opacity(${ (100 - step.captionTop) / 100 })`,
                    }
            );
        });
        const stepContentStyles = [step1, step2, step3].map(step => {
            return (
                isMobile ?
                    null :
                    {
                        transform: `translateY(${ step.contentTop }px)`,
                        filter: `opacity(${ (100 - step.contentTop) / 100 })`,
                    }
            );
        });
        const stepBigNumberStyles = [step1, step2, step3].map(step => {
            return (
                isMobile ?
                    null :{
                        transform: `translateY(-${ step.bigNumberTop }px)`,
                    }
            );
        });
        const smileFaceStyle = {
            transform: `translateX(-${ smileFacePos }%)`,
            opacity: smileFacePos / 30,
        };
        const videoList = [{
            ref: this.elVideoStep1,
            src: videoStep1,
            poster: posterStep1,
        }, {
            ref: this.elVideoStep2,
            src: videoStep2,
            poster: posterStep2,
        }, {
            ref: this.elVideoStep3,
            src: videoStep3,
            poster: posterStep3,
        }];
        const scooterShowcaseCls = classNames({
            'present-scooter': true,
            'trim': showStatic || trimcooter360Wrapper,
            'show-static-bg': showStatic,
        });
        const stepsCls = classNames({
            'steps': true,
            'is-mobile': (isMobile || showStatic),
        });

        return (
            <ViewContainer className="home">
                <Header show={ showHeader } darkLogo={ darkHeaderLogo } darkLink={ darkHeaderLink } />
                <Hero innerRef={ this.elHero } />
                <StandardContainer innerRef={ this.elScooter360 } className={ scooterShowcaseCls }>
                    { showScooter360 && !showStatic ? <div className="scooter-showcase" /> : null }
                </StandardContainer>
                <StandardContainer innerRef={ this.elSteps } className={ stepsCls } ghostHeader>
                    <section className="steps-caption">
                        <Translate className="caption" value="step.caption-1" tag="h2" />
                        <Translate className="caption" value="step.caption-2" tag="h2" />
                    </section>
                    {
                        isMobile || showStatic ? null : (
                            <div className="animation-wrapper">
                                <div ref={ this.elSmileFace } style={ smileFaceStyle } className="smile-face" />
                                <div className="holding-cellphone" />
                                <Videos list={ videoList } showIndex={ showVideoIndex } />
                            </div>
                        )
                    }
                    <div ref={ this.elStep1 } className="step step-1">
                        {
                            isMobile || showStatic ?
                                (
                                    <div className="step-video-wrapper">
                                        <StepVideo
                                            src={ videoList[0].src }
                                            poster={ videoList[0].poster }
                                            innerRef={ this.elVideoStep1 }
                                            hide={ 0 === showVideoIndex }
                                        />
                                    </div>
                                ) :
                                null
                        }
                        <article className="description">
                            <strong style={ stepBigNumberStyles[0] } className="step-number">1</strong>
                            <Translate style={ stepCaptionStyles[0] } className="step-caption" value="step.step-1-caption" tag="h3" />
                            <Translate style={ stepContentStyles[0] } className="step-content" value="step.step-1-content" tag="p" />
                        </article>
                    </div>
                    <div ref={ this.elStep2 } className="step step-2">
                        {
                            isMobile || showStatic ?
                                (
                                    <div className="step-video-wrapper">
                                        <StepVideo
                                            src={ videoList[1].src }
                                            poster={ videoList[1].poster }
                                            innerRef={ this.elVideoStep2 }
                                            hide={ 1 === showVideoIndex }
                                        />
                                    </div>
                                ) :
                                null
                        }
                        <article className="description">
                            <strong style={ stepBigNumberStyles[1] } className="step-number">2</strong>
                            <Translate style={ stepCaptionStyles[1] } className="step-caption" value="step.step-2-caption" tag="h3" />
                            <Translate style={ stepContentStyles[1] } className="step-content" value="step.step-2-content" tag="p" />
                        </article>
                    </div>
                    <div ref={ this.elStep3 } className="step step-3">
                        {
                            isMobile || showStatic ?
                                (
                                    <div className="step-video-wrapper">
                                        <StepVideo
                                            src={ videoList[2].src }
                                            poster={ videoList[2].poster }
                                            innerRef={ this.elVideoStep3 }
                                            hide={ 2 === showVideoIndex }
                                        />
                                    </div>
                                ) :
                                null
                        }
                        <article className="description">
                            <strong style={ stepBigNumberStyles[2] } className="step-number">3</strong>
                            <Translate style={ stepCaptionStyles[2] } className="step-caption" value="step.step-3-caption" tag="h3" />
                            <Translate style={ stepCaptionStyles[2] } className="step-content" value="step.step-3-content-1" tag="p" />
                        </article>
                    </div>
                </StandardContainer>
                <GoEverywhere innerRef={ this.elMovingScooter } run={ movingScooter } />
                <Promotion ghostHeader={ false } />
                <Footer />
            </ViewContainer>
        );
    }
}

export default withRouter(connect(state => ({
    i18n: state.i18n,
}))(Home));

const StepVideo = ({ innerRef, src, poster, hide }) => {
    const videoCls = classNames({
        'step-video': true,
        hide: (!showStatic && hide),
    });


    return (
        <React.Fragment>
            <img className={ videoCls } src={ poster } alt="" />
            <video
                className={ videoCls }
                ref={ innerRef }
                src={ src }
                preload="metadata"
                autoPlay={ showStatic }
                loop={ showStatic }
                playsInline
                muted
            />
        </React.Fragment>
    );
};

const Videos = ({ list, showIndex }) => (
    <>
        {
            list.map(({ ref, src, poster }, i) => {
                return (
                    <StepVideo
                        key={ `video-${ i }` }
                        innerRef={ ref }
                        src={ src }
                        poster={ poster }
                        hide={ !isMobile && (showIndex || 0) !== i }
                    />
                );
            })
        }
    </>
);
