const lang = {
    'hero-caption-1': '經濟部工業局補助',
    'hero-caption-2': ' ',
    'travel-title': '累積總里程數',
    'travel-description-1': '相當於繞地球 %{count} 圈',
    'travel-description-2': ' ',
    'saving-title': 'CO<sub>2</sub> 總排放量減少',
    'saving-description-1': '相當於每年 %{count} 棵樹的吸收量',
    'saving-description-2': ' ',
    'summary': '此數據係依照經濟部工業局補助之%{scooters}台已投入使用之GoShare電動機車與一般燃油機車相比溫室氣體減少之排放量。計算公式參考《行政院環境保護署審查開發行為溫室氣體排放量增量抵換處理原則總說明》，並以經濟部能源局最新公布110年電力排碳係數作為電動機車溫室氣體減量計算基礎。本數據將於能源局更新111年度及其後各年度電力排碳係數後，更新各該年度計算基礎。GoShare電動機車騎乘距離以進行電池交換時所上載於系統之數據為基礎。',
};

export default lang;
