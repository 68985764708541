const lang = {
    'business-inquiry': '商務合作',
    'brief-describe': '你好，歡迎和 GoShare 攜手改變世界！請提供以下資訊，有助於我們了解你的合作需求。',
    'business-opportunity': '合作需求',
    'your-contact-information': '請留下你的聯絡資訊，我們將於 7~14 個工作天內與你聯繫，謝謝。',
    'business-opportunity-placeholder': '請簡述預期的合作方式與項目。',
    'feedback-message': '感謝你的來信，我們將盡快與你聯繫！',
};

export default lang;
