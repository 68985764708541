const lang = {
    'business-inquiry': 'Business Inquiry',
    'brief-describe': 'Hello!  Welcome to the journey of making a difference with GoShare!  Let us know a bit about who you are and how you\'d like to work with us.',
    'business-opportunity': 'Business Opportunity',
    'your-contact-information': 'Please leave your contact details with us. We will get back to you within 7~14 business days, thank you.',
    'business-opportunity-placeholder': 'Please give us a short description of the business opportunity you are looking for.',
    'feedback-message': 'We have received your request and will get back to you soon. Thank you!',
};

export default lang;
