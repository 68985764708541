import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './standard-container.scss';


const Component = ({ className, tag, innerRef, ghostHeader, children, id }) => {
    const mainClass = classNames({
        'standard-container': true,
        'ghost-header': ghostHeader,
        [className]: className,
    });
    const wrapperClass = classNames({
        'wrapper': true,
    });
    const CustomTag = tag;

    return (
        <CustomTag ref={ innerRef } className={ mainClass } id={ id }>
            <div className={ wrapperClass }>
                { children }
            </div>
        </CustomTag>
    );
};

Component.propTypes = {
    ghostHeader: PropTypes.bool,
    innerRef: PropTypes.shape(),
    className: PropTypes.string,
    tag: PropTypes.string,
};

Component.defaultProps = {
    ghostHeader: false,
    innerRef: React.createRef(),
    className: '',
    tag: 'section',
};

export default Component;
