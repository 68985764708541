const lang = {
    'caption-1': '輕鬆啟程',
    'caption-2': '出發，兜風去 !',
    'step-1-caption': '快速註冊',
    'step-1-content': '上傳身分證、駕照與信用卡資料，快速完成註冊。',
    'step-2-caption': '一鍵尋車',
    'step-2-content': '輕鬆找尋附近最適合你的 Gogoro 智慧電動機車。',
    'step-3-caption': '簡單預約',
    'step-3-content-1': '預約成功後，使用 App 內導航功能找到 GoShare 車輛。車廂內放置可免費使用的安全帽，讓你隨時準備啟程！',
};

export default lang;
