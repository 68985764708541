const lang = {
    'caption-1-1': '搶先體驗 - 首次騎乘免費！',
    'caption-1-2': ' ',
    'caption-2-1': '推薦好友，享 $100 騎乘金',
    'caption-2-2': ' ',
    'subtitle-1': '* 限前 30 分鐘',
    'subtitle-2': '* 推薦次數無上限，邀請越多好友騎乘，就能得到越多騎乘金！詳見 GoShare App 說明。',
};

export default lang;
