const lang = {
    'title': '失物招領',
    'description-1': '掉了東西也別擔心！我們在這裡協助你！',
    'description-2': '請於下方清單查詢你的遺失物品編號，並致電客服協助處理。客服專線：(02) 77430695',
    'description-3': '我們將不定期更新資訊，祝你找回遺失的美好～',
    'notice': '* 小提醒：若在車輛上找到他人遺失物品，也請電洽客服，讓每個物品都能回到主人身邊喔！',
    'item-no': '編號',
    'date-found': '拾獲日期',
    'plate-no': '拾獲車牌',
    'item-description': '物品簡述',
};

export default lang;
