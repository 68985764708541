import React, { Component } from 'react';
import { Translate } from 'react-redux-i18n';
import PropTypes from 'prop-types';

import './service-switch.scss';

class serviceSwitch extends Component {
    static propTypes = {
        isDots: PropTypes.bool,
        onChange: PropTypes.func,
    };
    static defaultTypes = {
        isDots: false,
        onChange: () => {},
    };


    handleClick = () => {
        const { isDots, onChange } = this.props;
        onChange(!isDots);
    }

    render() {
        const { isDots } = this.props;
        const clsGoShare = !isDots ? 'on' : '';
        const clsDots = isDots ? 'on' : '';
        return (
            <div
                className="service-switch"
                onClick={ this.handleClick }
            >
                <Translate className={ `item ${clsGoShare}` } value="service.fun-and-easy-switch-goshare" tag="span" />
                <Translate className={ `item ${clsDots}` } value="service.fun-and-easy-switch-dots" tag="span" />
            </div>
        );
    }
}
export default serviceSwitch;
