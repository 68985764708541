import csv from 'csvtojson';
import { ROOT } from 'constants/route';
const ASSET_FOLDER = 'asset-data/';
const CSV_FILE_TYPE = '.csv';
const EXPORT_TO_CSV_DEFAULT = { output: 'csv' };


const getFilePath = (fileName) => {
    return `${ROOT}${ASSET_FOLDER}${fileName}${CSV_FILE_TYPE}`;
};

const getCSVData = (fileName, config = EXPORT_TO_CSV_DEFAULT) => {
    const path = getFilePath(fileName);

    return fetch(path)
    .then(res => {
        let reader = res.body.getReader();
        const decoder = new TextDecoder('utf-8');
        return reader
        .read()
        .then( async (result) => {
            const fileString = decoder.decode(result.value);
            let header = [];
            const data = await csv(config).fromString(fileString).on('header', (headers)=> header = headers);

            return {
                data,
                header,
            };
        });
    });
};

const getFileObject = async( fileName, stringHandler = () => {}, config) => {
    const { data, header } = await getCSVData(fileName, config);
    return stringHandler(data, header);
};


export default getFileObject;

export const checkValidHeader = (header, tmpl) => {
    const checkFileHeader = (value, index) => tmpl[index] ===header[index];
    return header.every(checkFileHeader);
};
