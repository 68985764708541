const fetchLangs = () => require.context('./translation/', true, /^.*\.js$/) || [];
const getKeyFromPath = path => {
    const matches = path.match(/^\.\/(.*)\.(js)$/) || [];
    const [ locale, group ] = matches[1]?.split('/') || [];

    return {
        lang: locale && locale.indexOf('-') ? locale.split('-')[0] : undefined,
        locale,
        group,
    };
};

export const getLocales = () => {
    const translations = getTranslations();

    return Object.keys(translations).reduce((locales, locale) => {
        locales[locale] = translations[locale].lang;

        return locales;
    }, {});
};

export const regionMap = {
    'tw': 'zh-TW',
    'us': 'en-US',
};

export const getTranslations = () => {
    const langs = fetchLangs();

    return langs.keys().reduce((translations, path) => {
        const { lang, locale, group } = getKeyFromPath(path);
        const currLang = langs(path).default;

        [lang, locale].forEach(key => {
            if (key !== undefined) {
                if (group === 'index') {
                    translations[key] = {
                        ...translations[key],
                        ...currLang
                    };
                }
                else {
                    translations[key] = {
                        ...translations[key],
                        [group]: currLang,
                    };
                }
            }
        });

        return translations;
    }, {});
};
