import React from 'react';
import ViewContainer from 'components/ViewContainer';

import './payment-3ds.scss';

const Back = () => {
    return (
        <ViewContainer>
            <div className="container">
                <div className="message-header"></div>
                <p className="message">哎呀綁定失敗，請關閉此視窗再重新嘗試一次。</p>
                <p className="eng-message">
                    <small>Oops! Something went wrong, please close the window and try again.</small>
                </p>
            </div>
        </ViewContainer>
    );
};

export default Back;
