const lang = {
    'caption-1-1': 'Can\'t wait to try?',
    'caption-1-2': 'First ride is free!',
    'caption-2-1': 'Share more, Earn more.',
    'caption-2-2': 'Refer a friend and earn $100.',
    'subtitle-1': '* Up to 30 mins',
    'subtitle-2': '* There are no limits to the number of times you can share. Please check the GoShare App for more details.',
};

export default lang;
