const lang = {
    'company-name': '公司名稱',
    'company-website': '公司網站',
    'located-country': '你所在的國家',
    'located-city': '你所在的城市',
    'first-name': '名字',
    'last-name': '姓氏',
    'phone': '聯絡電話',
    'email': '聯絡電子信箱',
    'phone-placeholder': '台灣以外的區域請加上國碼。',
    'valueMissing': '此欄位必填',
    'patternMismatch': '無效電子信箱',
    'general-error-message': '請確認內容',
    'submit': '提交'
}

export default lang;