const lang = {
    'lang': 'English(USA)',
    'notify-me': 'Notify me',
    'email-placeholder-unfocus': 'Be the first to know',
    'email-placeholder-focus': 'Input your email',
    'submit': 'Submit',
    'contact-us': 'Business Inquiry',
    'all-rights-reserved': '© %{year} Gogoro and/or its affiliates. All rights reserved.  Gogoro, Gogoro Network, GoShare, Smartscooter, GoStation, the logos thereof, and all other names and logos of Gogoro’s products and services are trademarks or registered trademarks of Gogoro Inc. All other names, products, services, and logos mentioned herein are the property of their respective owners.',
    'privacy-policy': 'Privacy Policy',
    'terms-and-conditions': 'Terms of Use',
    'terms-and-service': 'Terms of Service',
    'taoyuan': 'Taoyuan',
    'invalid-email': 'Please enter a valid email.',
    'go-now': 'Notify Me',
    'download-app': 'Download App',
    'available-platform': 'Available on iOS and Android',
    'nav-about-us': 'About Us',
    'goshare-jp': 'GoShare Japan - rental service',
    'our-service': 'Service',
    'nav-partnerships': 'Partnerships',
    'newsroom': 'Newsroom',
    'career': 'Career',
    'lost_found': 'Lost & Found',
    'general-error-message': 'Something went wrong, please try again:(',
    'goshare-data-notice': 'GoShare Data Notice',
};

export default lang;
