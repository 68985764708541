import { createStore as reduxCreateStore } from 'redux';
import { loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n';
import { getTranslations } from 'locale';

const store = (reducers, appliedMiddleware ) => {
    const translations = getTranslations();
    const store = reduxCreateStore(reducers, appliedMiddleware);
    syncTranslationWithStore(store);
    store.dispatch(loadTranslations(translations));
    const defaultLang = (navigator || {}).language;
    store.dispatch(setLocale(translations[defaultLang] ? defaultLang : 'en-US'));
    return store;
};

export default store;
