const { PUBLIC_URL } = process.env;

export const ROOT = (PUBLIC_URL + '/' || '/');
export const ABOUT_US = `${ ROOT }about-us`;
export const DOWNLOAD = `${ ROOT }download`;
export const NEWS_ROOM = `${ ROOT }news`;
export const SERVICE = `${ ROOT }service`;
export const SERVICE_TYPE_FREEFLOATING = 'freefloating';
export const SERVICE_TYPE_PRICING = 'pricing';
export const SERVICE_FREEFLOATING = `${ ROOT }service#${ SERVICE_TYPE_FREEFLOATING }`;
export const SERVICE_PRICING = `${ ROOT }service#${ SERVICE_TYPE_PRICING }`;
export const LOST_N_FOUND = `${ ROOT }lost-and-found`;
export const MA_PROGRAM = `${ ROOT }career-ma-program`;
export const PARTNERSHIPS = `${ ROOT }partnerships`;

export const PAYMENT_3DS_DONE = `${ ROOT }payment/3ds-done`;
export const PAYMENT_3DS_BACK = `${ ROOT }payment/3ds-back`;

export const SPECIAL_DYNAMIC_LINK_LIST = [
    `${ ROOT }routepayment`,
    `${ ROOT }routechallenge`,
    `${ ROOT }routecoupon`,
];

export const PRIVACY_BLOCK_LIST = [
    PAYMENT_3DS_DONE,
    PAYMENT_3DS_BACK,
];



let routes = {
    ROOT,
    ABOUT_US,
    DOWNLOAD,
    SERVICE,
    LOST_N_FOUND,
    NEWS_ROOM,
    MA_PROGRAM,
    PARTNERSHIPS,
};

routes = Object.keys(routes).reduce((newRoutes, key) => {
    newRoutes[`LANG_${ key }`] = `${ ROOT }:locale${ routes[key] }`;

    return newRoutes;
}, routes);

export default routes;
