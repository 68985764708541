import React, { Component } from 'react';
import { Translate } from 'react-redux-i18n';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import StandardContainer from 'components/StandardContainer';
import './promotion.scss';

const list = [(
    <article>
        <Translate value="additional-info.caption-1-1" className="caption" tag="h2" />
        <Translate value="additional-info.caption-1-2" className="caption" tag="h2" />
        <Translate value="additional-info.subtitle-1" className="subtitle" tag="p" />
    </article>
), (
    <article>
        <Translate value="additional-info.caption-2-1" className="caption" tag="h2" />
        <Translate value="additional-info.caption-2-2" className="caption" tag="h2" />
        <Translate value="additional-info.subtitle-2" className="subtitle" tag="p" />
    </article>
)];
const classList = ['first-ride', 'share-and-earn'];

class Promotion extends Component {
    static propTypes = {
        ghostHeader: PropTypes.bool,
    };

    static defaultProps = {
        ghostHeader: true,
    };

    constructor(props) {
        super(props);

        this.timer = undefined;
        this.state = {
            showIndex: 0,
        };
    }

    componentDidMount() {
        this.timer = setInterval(() => {
            const { showIndex } = this.state;
            const currIndex = showIndex + 1;

            this.setState({
                showIndex: (currIndex % list.length),
            });
        }, 5000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    renderContext(index) {
        return list[index];
    }

    render() {
        const { ghostHeader } = this.props;
        const { showIndex } = this.state;
        const context = this.renderContext(showIndex);
        const containerCls = classNames({
            'promotion': true,
            [classList[showIndex]]: true,
        });

        return (
            <StandardContainer className={ containerCls } ghostHeader={ ghostHeader }>
                { context }
                {/* <ButtonDownloadApp /> */}
            </StandardContainer>
        );
    }
}

export default Promotion;
