import React from 'react';
import { Translate } from 'react-redux-i18n';
import Footer from 'components/Footer';
import ViewContainer from 'components/ViewContainer';
import Header from 'components/Header';
import qrcode from 'assets/img/p2/img-app-qrcode.svg';
import './download.scss';


const Component = () => {
    return (
        <ViewContainer className="download">
            <Header show />
            <div className="container">
                <Translate className="caption" value="download.caption" tag="h2" />
                <Translate className="subtitle" value="download.subtitle" tag="p" />
                <img className="dynamic-link" src={ qrcode } alt="" />
                <Translate className="description" value="available-platform" tag="p" />
            </div>
            <Footer />
        </ViewContainer>
    );
};

export default Component;
