import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { detect } from 'detect-browser';
import { setLocale } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import { regionMap } from 'locale';

const browser = detect();

class ViewContainer extends Component {
    static propTypes = {
        className: PropTypes.string,
        innerRef: PropTypes.shape(),
    };
    static defaultProps = {
        className: '',
        innerRef: React.createRef(),
    };

    componentDidMount() {
        this.handleLangChanged();
    }

    componentDidUpdate(prevProps) {
        this.handleLangChanged(prevProps);
    }

    handleLangChanged = (prevProps = { match: { params: {} } }) => {
        const { dispatch, match } = this.props;
        const currLocale = match.params[0];
        const prevLocale = prevProps.match.params[0];

        if (prevLocale !== currLocale) {
            if (regionMap[currLocale]) {
                dispatch(setLocale(regionMap[currLocale]));
            }
            else {
                dispatch(setLocale('en-US'));
            }
        }
    }

    render() {
        const { className, children, innerRef } = this.props;
        const classMap = classNames({
            'view': true,
            [browser.name]: true,
            [className]: className,
        });

        return (
            <div className={ classMap } ref={ innerRef }>
                { children }
            </div>
        );
    }
}


export default withRouter(connect()(ViewContainer));
