import React from 'react';
import { Translate } from 'react-redux-i18n';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './pricing-motion.scss';

const Component = ({ innerRef, active, isDots }) => {
    const cls = classNames({
        'pricing-motion': true,
        'active': active,
        'type-dots': isDots,
    });

    return (
        <div ref={ innerRef } className={ cls }>
            <div className="pricing-fixed">
                <div className="line">
                    <div className="solid-line" />
                    <div className="grow-line" />
                </div>
                {
                    isDots?
                    <Translate className="text left" value="service.pricing-motion-description-dots-1" tag="div" /> :
                    <Translate className="text left" value="service.pricing-motion-description-1" tag="div" />
                }
            </div>
            <div className="pricing-minute">
            <div className="grow-line" />
                <div className="line">
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                </div>
                {
                    isDots?
                    <Translate className="text right" value="service.pricing-motion-description-dots-2" tag="div" /> :
                    <Translate className="text right" value="service.pricing-motion-description-2" tag="div" />
                }
            </div>
        </div>
    );
};
Component.propTypes = {
    innerRef: PropTypes.shape(),
    active: PropTypes.bool,
};

Component.defaultProps = {
    innerRef: React.createRef(),
    active: false,
};

export default Component;
