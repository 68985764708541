import React from 'react';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { ROOT } from 'constants/route';
import { connect } from 'react-redux';
import { regionMap } from 'locale';



const Component = ({ to, children, className, match, target = '_self', i18n, location }) => {
    const getLocalePath = (value, parentPath) => {
        const path = Object.keys(regionMap).find(key => regionMap[key] === value);
        return (parentPath.indexOf(path)>-1) ? path : undefined;
    };
    const { params } = match;
    const { pathname } = location;
    const locale = (target==='_blank') ? getLocalePath(i18n.locale, pathname) : params[0];
    const realPath = locale ? `${ ROOT }${ locale }${ to }` : to;

    return (
        <NavLink className={ className } to={ realPath } target={ target }>
            { children }
        </NavLink>
    );
};

export default withRouter(connect(state => ({ i18n: state.i18n }))(Component));
