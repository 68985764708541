import { combineReducers } from 'redux';
import storeCreater from './util/store-creater';
import { i18nReducer } from 'react-redux-i18n';
import general from './genral';


export default combineReducers({
    general,
    i18n: i18nReducer,
});

export const createStore = storeCreater;
