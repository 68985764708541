import React from 'react';
import Footer from 'components/Footer';
import ViewContainer from 'components/ViewContainer';
import Header from 'components/Header';
import './not-found.scss';


const Component = () => {
    return (
        <ViewContainer className="not-found">
            <Header show sticky />
            <div className="container">
                <h1>Not Found</h1>
            </div>
            <Footer />
        </ViewContainer>
    );
};

export default Component;
