import React, { useState } from 'react';
import MapPng from 'assets/img/map-animation/map.png';

import './map-animation.scss';

const MapAnimation = () => {
    const [mapLoaded, setMapLoaded] = useState(false);

    return (
        <div className="map-animation">
            <img className="worldmap"
                src={ MapPng }
                alt=""
                onLoad={ () => setMapLoaded(true) } />
            {
                !mapLoaded ? null : (
                    <>
                        <div className="dot dot-taiwan">
                            <span />
                            <span />
                            <span />
                        </div>
                        <div className="dot dot-jakarta">
                            <span />
                            <span />
                            <span />
                        </div>
                        <div className="dot dot-singapore">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <div className="dot dot-philippines">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <div className="location" />
                        <div className="path">
                            <svg width="100px" height="176px" viewBox="0 0 100 176" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <title>path</title>
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                                    <g id="path" transform="translate(1.500050, 2.179655)" stroke="#01FFDA" strokeWidth="3">
                                        <path d="M96.8114638,0 C69.7668512,23.1530591 49.8841768,48.6986293 37.1634406,76.6367104 C24.4427044,104.574791 18.316865,136.150755 18.7859225,171.364602" id="Path-2"></path>
                                        <path d="M96.8114638,0 C69.399843,8.9980383 46.1578934,26.1561319 27.0856148,51.4742807 C8.01333622,76.7924296 -0.98666378,103.574451 0.0856147882,131.820345" id="Path-2"></path>
                                        <path d="M96.8114638,0 C91.1023229,7.63804165 87.8209409,16.7515293 86.9673178,27.340463 C86.1136946,37.9293966 87.4864603,48.0893572 91.0856148,57.8203448" id="Path-2"></path>
                                    </g>
                                </g>
                            </svg>
                        </div>
                    </>
                )
            }
        </div>
    );
};
export default MapAnimation;
