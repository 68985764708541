import React, { Component } from 'react';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Hero from 'components/Hero';
import ViewContainer from 'components/ViewContainer';
import getFileObject, { checkValidHeader } from 'helpers/read-file';
import xss from 'xss';

import './newsroom.scss';

const xssOptionForRequest = {
    whiteList: {
        a: ['href', 'target']
    }
};

const NEWS_COUNT_IN_PAGE = 10;
const NEWS_FILE_HEADER = ['公佈日期*', '媒體來源*', '標題*', '新聞網址*', '照片網址(若無留空)'];


class News extends Component {
    constructor(props) {
        super(props);

        this.state = {
            newsList: undefined,
        };
    }
    componentDidMount() {
        this.fetchNewsroomCSV();
    }

    checkValidFileFormat = (header, firstItem) => {
        const isValidHeader = checkValidHeader(header, NEWS_FILE_HEADER);
        return !!(isValidHeader && firstItem && firstItem.length >= 4);
    }

    getLangByRouteString = (str = 'defaultLang') => {
        const { i18n } = this.props;
        const langMatch = {
            tw: 'tw',
            us: 'en',
            defaultLang: (i18n.locale.indexOf('zh') > -1 ? 'tw' : 'en'),
        };
        return langMatch[str];
    }

    fetchNewsroomCSV = async() => {
        const { match } = this.props;
        // Check lang by route first, then use default setting
        const region = this.getLangByRouteString(match.params[0]);
        const fileName = 'newsroom_' + region;
        await getFileObject(fileName, (rows, header) => {
            const isValid = this.checkValidFileFormat(header, rows[0]);
            const newsList = isValid ? rows.slice(0, NEWS_COUNT_IN_PAGE) : undefined;
            return newsList;
        }).then( newsList => {
            this.setState({
                newsList,
            })
        });
    }

    render() {
        const { newsList } = this.state;
        return (
            <ViewContainer className="newsroom">
                <Header show sticky />
                <Hero langkey="news.title" />
                {
                    newsList &&
                    newsList.length &&
                    <ul className="news-list">
                        {
                            newsList.map((news, i) => <NewsItem key={ i } data={ news } />)
                        }
                    </ul>
                }
                <p className="request-notice" dangerouslySetInnerHTML={ { __html: xss(I18n.t('news.request'), xssOptionForRequest) } } />
                <Footer />
            </ViewContainer>
        );
    }
}

export default withRouter(connect(state => state)(News));

const NewsItem = ({ data: [date, source, caption, link, pictureLink] }) => {
    const Wrapper = (link ? 'a' : 'div');
    const wrapperCls = classNames({
        'wrapper': true,
        'with-pic': !!pictureLink,
    });
    const props = (
        link ? {
            href: link,
            target: '_blank',
            rel: 'noopener noreferrer',
        } : {}
    );
    const inlineStyle = {
        backgroundImage: `url(${ pictureLink })`,
    };

    return (
        <li className="news-item">
            <Wrapper className={ wrapperCls } { ...props }>
                <article className="content">
                    <h6>
                        <time className="publish-time" dateTime={ date }>{ date }</time>
                        <span>{ source }</span>
                    </h6>
                    <h2>{ caption }</h2>
                </article>
                {
                    (pictureLink ? <div className="news-picture" style={ inlineStyle } /> : null)
                }
            </Wrapper>
        </li>
    );
};
