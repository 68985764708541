const lang = {
    'caption-1': 'Go everywhere. Go forever',
    'description-1-1': 'Hop on and enjoy the ride. Unlike other sharing services, GoShare gives you access to the Gogoro Network® so you can swap your batteries at any GoStation easily and extend your ride.',
    'description-1-2': '* Please refer to GoShare App or Gogoro Network website for the details on GoStation locations.',
    'read-more': 'Read More',
    'caption-2': 'Explore Popular Tourist Attractions',
    'description-2-1': 'Specifically designed for popular tourist destinations, GoShare DOTS lets you reserve and return a GoShare DOTS scooter at designated stops 24/7. Find a bright yellow dot in the GoShare App to enjoy a ride.',
    'caption-3': 'Flexible Rates',
    'description-3-1': 'Offering multiple pricing options such as riding rates and pause rates. Whether you’re making a quick stop or waiting for someone, easily switch with just one tap for great savings!',
    'description-3-2': 'Looking for a long-term rental? The "Day Pass" offers a more flexible choice, with the freedom to switch between different scooter models during the daily rental period!',
    'description-3-3': '* Please refer to your GoShare App for actual rates and promotions.',
};

export default lang;
