const lang = {
    'title': 'Explore with GoShare',
    'hero-caption-1': '透過 「Explore with GoShare 全球願景夥伴聯盟」，GoShare 攜手更多志同道合的全球夥伴，持續拓展移動共享生態系，一同實現綠色經濟帶來的影響力，以達成 2030 年聯合國永續發展目標 (SDGs)，為下一代打造更乾淨、<span class="text-wrap-xs">更智慧的未來。</span>',
    'hero-caption-2': ' ',
    'cta-btn': '聯絡我們',
    'gojek-title': '攜手 Gojek，插旗雅加達',
    'gojek-intro': '<span class="text-wrap">GoShare 前進印尼雅加達，</span><span class="text-wrap-ex-sm"><span class="text-wrap-sm">並成為東南亞最大的移動服務平台 </span>- Gojek 實現 2030 年淨零碳排</span><span class="text-wrap-ex-sm"><span class="text-wrap-ex-sm">與電動化轉型<span class="text-wrap-sm"><span class="text-inline-ex-sm">的首發策略夥伴，</span>整合電動機車、能源網路、</span>雲端管理、</span></span><span class="text-inline-sm">手機 App，</span><span class="text-wrap-sm">為叫車與外送服務提供企業專屬車隊<span class="text-wrap">與 GoStation 電池交換站。</span></span>',
    'gojek-quote': '<span class="text-wrap-sm">「我們致力達成 2030 年車隊完全電動化，</span>並實現零排放的未來目標。<span class="text-wrap-sm">透過與 Gogoro 的創新能力互相結合以加速變革，</span>與更多的夥伴及消費者分享電動車輛的好處。」<span class="text-wrap">- Gojek 聯合創辦人兼執行長 Kevin Aluwi</span>',
    'gfb-title': '<span class="text-wrap-sm">為永續而生的</span>移動解決方案',
    'gfb-caption': '提供員工更便利的<span class="text-wrap-sm">綠色交通</span>',
    'gfb-intro': 'GoShare for Business 企業方案根據不同企業與員工的多元移動需求，量身打造環保便利的移動解決方案，並邀請企業夥伴加入降低空汙、推廣綠能環保的行列，協助企業達成社會企業責任目標。',
    'fc-caption': '拓展 GoShare 海外服務',
    'fc-intro': '我們正在尋找全球各地具備營運與社群互動經驗的合作夥伴，共同將深受喜愛的 GoShare 品牌與創新騎乘體驗帶給更多消費者，同時協助夥伴拓展<span class="text-wrap-sm">智慧移動共享服務。</span>',
    'gov-caption': '攜手政府完善<span class="text-wrap-sm">城市交通建設</span>',
    'gov-intro': '政府與公部門是 GoShare 推動智慧城市的關鍵合作夥伴。GoShare 連結公共運輸系統，因應不同的城市移動場景提供適合的移動解決方案，進而打造便捷靈活、永續發展、持續優化升級的交通網絡。',
    'others-caption': '品牌合作創造綜效',
    'others-intro': '我們堅信獨特與激勵人心的用戶體驗能夠譜寫精彩的故事，並建立強大的品牌。我們迫不及待迎接更多品牌夥伴的加入，共同為用戶帶來更多驚喜。準備好一起創造更多新樂趣了嗎？',
    'partners-caption': '與頂尖品牌<span class="text-wrap-sm">一同改變世界</span>',
};

export default lang;
