const lang = {
    'caption-1': 'Start your city adventure.',
    'caption-2': 'It just takes a few simple steps.',
    'step-1-caption': 'Fast & easy set-up',
    'step-1-content': 'With the GoShare App, registering is a breeze. With just a few simple steps, you will be up and running in no time.',
    'step-2-caption': 'Find your ride with one click',
    'step-2-content': 'Access to a fleet of exciting Gogoro Smartscooters is in the palm of your hand.',
    'step-3-caption': 'Reserve quickly',
    'step-3-content-1': 'Book the ride you want and let the app guide you to it. When you reach your ride, everything you need is waiting for you.',
};

export default lang;
