const lang = {
    'title': 'Lost & Found',
    'description-1': 'Nobody likes to lose stuff. At GoShare, we got you covered.',
    'description-2': 'Just check the lost & found list below and find the No. of your lost item. Then, please call our Customer Service for further assistance.',
    'description-3': 'Customer Service hotline: +886-2-77430695',
    'notice': '* If you find others’ belongings in the scooter, please kindly contact our service team. Thank you.',
    'item-no': 'No.',
    'date-found': 'Date Found',
    'plate-no': 'Plate No.',
    'item-description': 'Item',
};

export default lang;
