const lang = {
    'hero-caption-1': 'Subsidies from Industrial Development Bureau,',
    'hero-caption-2': 'Ministry of Economic Affairs',
    'travel-title': 'Total distance traveled',
    'travel-description-1': 'As far as the distance of circling',
    'travel-description-2': 'the Earth around the equator %{count} times.',
    'saving-title': 'CO<sub>2</sub> saved',
    'saving-description-1': 'As much as the amount of CO2',
    'saving-description-2': 'that %{count} trees absorb every year.',
    'unit-km': 'km',
    'unit-kg': 'kg',
    'summary': 'This is the reduction in greenhouse gas emissions of %{scooters} subsidized GoShare scooters compared with fuel scooters. The calculation formula refers to the “EPA Disposal Directions for Reviewing the Offset of Greenhouse Gas Emission from a Development Activity” in Taiwan. The riding distance of the 5509 subsidized GoShare scooters is based on the data uploaded to the system when the batteries are swapped.',
};

export default lang;
