import React from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import PropTypes from 'prop-types';
import xss from 'xss';

I18n.setHandleMissingTranslation(text => text);

class XSSTranslate extends React.Component {
    static propTypes = {
        value: PropTypes.string.isRequired,
        whiteList: PropTypes.shape(),
    };

    static defaultProps = {
        whiteList: xss.getDefaultWhiteList(),
    };

    render () {
        const { whiteList, value, ...restProps } = this.props;
        const xssOption = {
            whiteList,
            stripIgnoreTag: true,
        };
        const content = xss(I18n.t(value, {  ...restProps }), xssOption);

        return (
            <>
                <Translate
                    { ...this.props }
                    dangerousHTML
                    value={ content }
                />
            </>
        );
    };
}

export default XSSTranslate;
