import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import XSSTranslate from 'components/XSSTranslate';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Hero from 'components/Hero';
import ViewContainer from 'components/ViewContainer';
import StandardContainer from 'components/StandardContainer';
import MapAnimation from './MapAnimation';
import gfbImg from 'assets/img/p2/p-content-1.png';
import fcImg from 'assets/img/p2/p-content-2.png';
import govImg from 'assets/img/p2/p-content-3.png';
import othersImg from 'assets/img/p2/p-content-4.png';
import classNames from 'classnames';
import expLogo from 'assets/img/p2/logo-exp.svg';
// Partners LOGO
import { partnerships } from 'constants/partnerships';

import './partnerships.scss';

const LOGO_ROW_COUNT = 5;

class Partnerships extends Component {

    constructor(props) {
        super(props);
        this.headerEl =  React.createRef();
        this.scrollSectionsEl = [React.createRef(), React.createRef()];
        this.observer = undefined;
    }

    componentDidMount() {
        this.createObserver();
    }
    componentWillUnmount() {
        if (this.observer) {
            this.observer.disconnect();
        }
    }
    createObserver = () => {
        const { current: headerEl } = this.headerEl;
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0,
        }

        const onIntersect = (entries) => {
            entries.forEach(entry => {
                const isHeroEl = entry.target.classList.contains('hero');
                if (headerEl) {
                    const { top, height } = this.scrollSectionsEl[0].current.getBoundingClientRect();
                    if (top > -1 * height || !window.pageYOffset || (entries[0].isIntersecting && isHeroEl)) {
                        headerEl.classList.add('transparent-header');
                    }
                    else {
                        headerEl.classList.remove('transparent-header');
                    }
                }
            });
        }
        this.observer = new IntersectionObserver(onIntersect, options);
        this.scrollSectionsEl.forEach(section => {
            this.observer.observe(section.current);
        });
    }

    getPartnersList = (isExpanded) => {
        const list = partnerships.map((item, index) => {
            let result = [];
            if ( isExpanded &&
                index &&
                index < partnerships.length -1 &&
                index % LOGO_ROW_COUNT === 0) {
                result.push({ id: index.toString, style: 'is-break'});
            }

            result.push(item);

            return result;
        }).flat();
        return list;
    }

    renderBreakItem = (index) => {
        return (<li className="partners-item is-break" key={ index }></li>);
    }

    renderPartnersList = (forceExpand = null) => {
        const isExpanded = forceExpand ?? !!(partnerships.length % LOGO_ROW_COUNT === 1);
        const list = this.getPartnersList(isExpanded);

        const style = classNames({
            'partners-list': true,
            'is-expanded': isExpanded,
        });
        return (
            <ul className={ style }>
                {
                    list.map(item => (
                        <li className={ 'partners-item ' + (item.style || '') } key={ item.id } >
                            {
                                (item.logo) ? <img src={ item.logo } alt=""/> : null
                            }
                        </li>
                    ))
                }
            </ul>
        );
    }



    render () {
        const XSSWhiteList = {
            span: ['class'],
        };

        return (
            <ViewContainer className="partnerships">
                <Header show sticky bgdark  innerRef={ this.headerEl } />
                <Hero bgdark ref={ this.scrollSectionsEl[0] }>
                    <MapAnimation />
                    <section className="context">
                        <img className="logo" src={ expLogo } alt={ I18n.t('partnerships.title') } />
                        <XSSTranslate className="para" value="partnerships.hero-caption-1" tag="p" whiteList={ XSSWhiteList } />
                        <Translate className="para" value="partnerships.hero-caption-2" tag="p" />
                    </section>
                </Hero>
                <StandardContainer className="features" innerRef={ this.scrollSectionsEl[1] }>
                    <XSSTranslate value="partnerships.gfb-title" tag="h2" whiteList={ XSSWhiteList } />
                    <section className="feature-wrapper">
                        <div className="col image">
                            <img src={ gfbImg } alt={ I18n.t('partnerships.gfb-caption') } />
                        </div>
                        <div className="col description">
                            <XSSTranslate value="partnerships.gfb-caption" className="caption" tag="h3" whiteList={ XSSWhiteList } />
                            <XSSTranslate value="partnerships.gfb-intro" className="content" tag="p" whiteList={ XSSWhiteList } />
                            <a href="mailto:partner@ridegoshare.com" className="btn btn-primary btn-cta" target="_blank" rel="noopener noreferrer">
                                <Translate value="partnerships.cta-btn" />
                            </a>
                        </div>
                    </section>
                    <section className="feature-wrapper">
                        <div className="col image">
                            <img src={ fcImg } alt={ I18n.t('partnerships.fc-caption') } />
                        </div>
                        <div className="col description">
                            <XSSTranslate value="partnerships.fc-caption" className="caption" tag="h3" whiteList={ XSSWhiteList } />
                            <XSSTranslate value="partnerships.fc-intro" className="content" tag="p" whiteList={ XSSWhiteList } />
                            <a href="mailto:partner@ridegoshare.com" className="btn btn-primary btn-cta" target="_blank" rel="noopener noreferrer">
                                <Translate value="partnerships.cta-btn" />
                            </a>
                        </div>
                    </section>
                    <section className="feature-wrapper">
                        <div className="col image">
                            <img src={ govImg } alt={ I18n.t('partnerships.gov-caption') } />
                        </div>
                        <div className="col description">
                            <XSSTranslate value="partnerships.gov-caption" className="caption" tag="h3" whiteList={ XSSWhiteList } />
                            <Translate value="partnerships.gov-intro" className="content" tag="p" />
                            <a href="mailto:partner@ridegoshare.com" className="btn btn-primary btn-cta" target="_blank" rel="noopener noreferrer">
                                <Translate value="partnerships.cta-btn" />
                            </a>
                        </div>
                    </section>
                    <section className="feature-wrapper">
                        <div className="col image">
                            <img src={ othersImg } alt={ I18n.t('partnerships.others-caption') } />
                        </div>
                        <div className="col description">
                            <Translate value="partnerships.others-caption" className="caption" tag="h3" />
                            <Translate value="partnerships.others-intro" className="content" tag="p" />
                            <a href="mailto:partner@ridegoshare.com" className="btn btn-primary btn-cta" target="_blank" rel="noopener noreferrer">
                                <Translate value="partnerships.cta-btn" />
                            </a>
                        </div>
                    </section>
                </StandardContainer>
                <StandardContainer className="feature-partners">
                    <XSSTranslate value="partnerships.partners-caption" tag="h2" whiteList={ XSSWhiteList } />
                    {
                        this.renderPartnersList(false)
                    }
                </StandardContainer>
                <Footer />
            </ViewContainer>
        );
    }
}
export default connect(state => ({ i18n: state.i18n }))(Partnerships);
