import { GET_YEAR } from 'constants/action-type';
import creater from './util/creater';

const initialState = {
    year: undefined
};

const actionMap = {
    [GET_YEAR]: (state, action) => {
        return {
            ...state,
            year: action.payload,
        };
    },
}

export default creater(actionMap, initialState);
