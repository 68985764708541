import axios from 'axios';

const { REACT_APP_API_BASE_URL } = process.env;

const TIME_INFO = '/time_info';

const axiosInstance = axios.create({
    baseURL: REACT_APP_API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    }
});

export const fetchYear = () => {
    return axiosInstance.get(TIME_INFO);
};

