const lang = {
    'title': '滿足你日常移動的<span>所有需求</span>',
    'model-f-caption': '通勤族新選擇',
    'model-f-subcaption': 'Gogoro VIVA / Gogoro JEGO',
    'model-f-feature-1': '輕巧設計，自在騎乘，短途通勤輕鬆有型。',
    'model-f-feature-2': '需具備中華民國「普通輕型機車」駕駛資格。',
    'model-f-charging-1': '搭配無線充電手機架，滿電出發。',
    'model-f-charging-2': '* 手機需支援無線充電',
    'model-b-caption': '城市探險好夥伴',
    'model-b-subcaption': '<span class="text-wrap-sm">Gogoro VIVA MIX / Gogoro 3 / </span>Ai-1 Comfort / Gogoro 2',
    'model-b-feature-1': '絕佳性能與續航力，城市探險的最佳選擇。',
    'model-b-feature-2': '需具備中華民國「普通重型機車」駕駛資格。',
    'scan-and-go-caption': 'SCAN & GO',
    'scan-and-go-content': '掃描 GoShare 或 GoShare DOTS 車輛後方的 QR Code，即可預約並解鎖車輛，超方便！',
    'tap-and-go-caption': 'TAP & GO',
    'tap-and-go-content': '<span class="text-wrap-sm">輕輕一觸，即刻出發！</span><span class="text-wrap-sm">全球首家整合</span> Apple Pay 與 NFC 科技的<span class="text-wrap">共享電動機車服務，僅需 iPhone </span><span class="text-wrap">感應即可一步預約 & 輕鬆支付！</span>',
    'tap-and-go-note': '* iPhone XR 或以上機型適用',
    'fun-and-easy-caption': '首騎免費，<span>驚喜優惠等你拿！</span>',
    'fun-and-easy-content': '首次騎乘前 30 分鐘免費！',
    'fun-and-easy-switch-goshare': 'GoShare',
    'fun-and-easy-switch-dots': 'GoShare DOTS',
    'pricing-motion-description-1': '前 6 分鐘採固定費率',
    'pricing-motion-description-2': '之後以每分鐘計費',
    'pricing-motion-description-dots-1': '前 15 分鐘採固定費率',
    'pricing-motion-description-dots-2': '之後以每分鐘計費',
    'pause-fee-notice': '<span class="text-wrap">搭配多元騎乘方案，</span><span>還可隨時切換至「暫停模式」，</span><span>行程安排更靈活！</span>',
    'fun-and-easy-notice': '* 實際費率與優惠活動請以 GoShare App 為主。',
    'find-us-in-ur-city-caption': '我們在以下城市<span>提供服務唷！</span>',
    'find-us-in-ur-city-notice-1': '* 新北市「青春山海線」為全台最長的綠能觀光旅遊路線，推廣以低碳交通運具體驗最美的北海岸風景。',
    'find-us-in-ur-city-notice-2': '* 詳細營運地區請參考 GoShare App。',
    'find-us-in-ur-city-title': 'GoShare 隨借隨還',
    'find-us-in-ur-city-description': '<span>想去哪就去哪，只要在營運範圍內的合法公共路側停車格，</span><span>都可以 24 小時隨借隨還 GoShare！</span>',
    'find-us-in-dots-title': 'GoShare DOTS 定點借還',
    'find-us-in-dots-description': '<span>專為熱門景點量身打造 - 24 小時定點借還的 GoShare DOTS，</span><span>A 點借 B 點還，人氣景點暢行無阻！</span>',
    'taipei': '台北',
    'taoyuan': '桃園',
    'tainan': '台南',
    'newTaipei': '新北',
    'yunlin': '雲林',
    'kaohsiung': '高雄',
    'taichung': '台中',
    'hsinchu': '新竹',
};

export default lang;

