import React, { Component } from 'react';
import { Translate } from 'react-redux-i18n';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Hero from 'components/Hero';
import ViewContainer from 'components/ViewContainer';
import getFileObject, { checkValidHeader } from 'helpers/read-file';

import './lost-items.scss';

const LOSTITEMS_FILE_NAME = 'lost';
const LOSTITEMS_FILE_HEADER = ['拾獲日期*', '車牌號碼*', '物品簡述(總筆數最多5000，簡述超過 17 個中文字會被刪減)*', '內部編號*'];

class LostItems extends Component {
    constructor(props) {
        super(props);

        this.state = {
            list: [],
        };
    }

    componentDidMount() {
        this.fetchLostAndFound();
    }

    checkValidFileFormat = (header, firstitem) => {
        const isValidHeader = checkValidHeader(header, LOSTITEMS_FILE_HEADER);
        return !!(isValidHeader && firstitem.length >= 3);
    }

    fetchLostAndFound = async() => {
        await getFileObject(LOSTITEMS_FILE_NAME, (rows, header) => {
            const isValid = this.checkValidFileFormat(header, rows[0]);
            const lostList = isValid ? rows : undefined;
            return lostList;
        })
        .then(list => {
            this.setState({
                list,
            });
        });

    }

    render() {
        const { list } = this.state;

        return (
            <ViewContainer className="lost-items">
                <Header show sticky />
                <Hero langkey="lost-items.title" />
                <section className="content">
                    <article className="description">
                        <Translate className="line" value="lost-items.description-1" tag="p" />
                        <Translate className="line" value="lost-items.description-2" tag="p" />
                        <Translate className="line" value="lost-items.description-3" tag="p" />
                    </article>
                    <Translate className="notice" value="lost-items.notice" tag="p" />
                    <section className="lost-item-list">
                        <table>
                            <thead>
                                <tr>
                                    <Translate value="lost-items.item-no" tag="th" />
                                    <Translate value="lost-items.date-found" tag="th" />
                                    <Translate value="lost-items.plate-no" tag="th" />
                                    <Translate value="lost-items.item-description" tag="th" />
                                </tr>
                            </thead>
                            <tbody>
                            {
                                list && list.length > 0 && list.map((item, i) => <Item key={ i } no={ i + 1 } data={ item } />)
                            }
                            </tbody>
                        </table>
                    </section>
                </section>
                <Footer />
            </ViewContainer>
        );
    }
}

export default LostItems;

const Item = ({ no, data: [date, plateNo, description] }) => {
    return (
        <tr className="item">
            <td>{ ('00000' + no).slice(-6) }</td>
            <td>{ date }</td>
            <td>{ plateNo }</td>
            <td className="item-description">{ description }</td>
        </tr>
    );
};
