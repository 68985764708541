import React, { useEffect } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import Privacy from 'components/Privacy'
import './app.scss';

ReactGA.initialize('UA-142477757-1', { debug: process.env.REACT_APP_ENV === 'dev' });

const App = ({ i18n, children, location }) => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        ReactGA.pageview(window.location.pathname);
    }, [location]);

    const { locale } = i18n;
    const [ lang ] = locale.split('-');
    const appClassName = classNames({
        'app': true,
        [locale.toLowerCase()]: true,
        [lang]: true,
    });

    return (
        <div className={ appClassName }>
            { children }
            <Privacy />
        </div>
    )
}

export default withRouter(connect(state => state)(App));
