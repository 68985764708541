const lang = {
    'title': 'Explore with GoShare',
    'hero-caption-1': 'Sustainability is not by choice, but a necessity. We believe that immediate, impactful initiatives through global partnerships are critical in delivering the United Nations Sustainable Development Goals (SDGs) by 2030, serving as the building blocks for a cleaner and smarter future.',
    'hero-caption-2': 'Join the GoShare Partner Program that enables partners in all spheres, and make the green economic impact through our end-to-end mobility sharing ecosystem.',
    'cta-btn': 'Become Our Partner',
    'gojek-title': '<span class="text-wrap">Partnering with Gojek,</span>GoShare Goes to Jakarta',
    'gojek-intro': 'GoShare expands global footprint <span class="text-wrap-sm">to Jakarta, Indonesia, </span><span class="text-wrap">and acts as the first strategic partner of Gojek, </span>Southeast Asia’s leading mobile on-demand services platform, <span class="text-wrap-ex-sm">to achieve their environmental sustainability agenda by 2030.</span><span class="text-wrap-sm">We integrate Gogoro Smartscooters, Gogoro </span>Network, advanced cloud management, and a mobile App into a turnkey business solution, <span class="text-wrap-ex-sm">delivering exclusive vehicles and GoStation to support </span>Gojek’s ride-hailing and delivery service.',
    'gojek-quote': '“We are committed to fully electrifying our fleet vehicles throughout Indonesia by 2030 and achieving a zero-emission future. The addition of Gogoro’s capabilities will accelerate change and share the benefits of electric vehicles with more driver-partners and consumers.” <span class="text-wrap">- Kevin Aluwi, CEO & Co-founder, Gojek.</span>',
    'gfb-title': 'Mobility as a <span class="text-wrap-sm">Sustainable Solution</span>',
    'gfb-caption': 'Empower a Meaningful Journey for Your Employees ',
    'gfb-intro': 'With GoShare for Business, we offer accessible and environmentally friendly mobility solutions for your employees, all tailored to meet multiple mobility needs and lower your organization’s <span class="text-wrap-xs">carbon footprint.</span>',
    'fc-caption': 'Bring GoShare to <span class="text-wrap-sm">Your City</span>',
    'fc-intro': 'As GoShare expands globally, we extend an invitation to partners equipped with extensive local operational expertise and solid community engagement, ready to work closely with us in bringing the game-changing GoShare experience to your city and expand your service offerings.',
    'gov-caption': 'Complement Transportation Infrastructure in Your City',
    'gov-intro': 'Governments play a critical role in GoShare\'s lifelong quest to build smart cities. Designed to integrate with public transportation infrastructure, GoShare can seamlessly be adapted to suit a host of urban mobility scenarios, and a powerful addition in shaping a robust transportation network that is extremely easy to use, sustainable to operate, and 100% scalable.',
    'others-caption': 'Explore Brand Synergy',
    'others-intro': 'We believe that unique and inspiring user experiences tell compelling stories and build strong brands that stand the test of time. We are excited to have you on board and engage our audiences with surprises and delights. Ideas for a fun collaboration? Let\'s chat!',
    'partners-caption': 'Make a Difference with World-Class Partners',
};

export default lang;
