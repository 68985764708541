import lottie from 'lottie-web';

export const renderAnimation = (el, data, isLoop = true) => {
    return lottie.loadAnimation({
        container: el,
        renderer: 'svg',
        loop: isLoop,
        autoplay: true,
        animationData: data,
    });
};
